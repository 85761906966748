<template>
  <div>
    <div class="flex-def flex-zBetween flex-cCenter" style="margin-bottom: 1rem">
      <div class="y-desc">
        <div>仅普通商品可添加至乡镇专区</div>
        <div>商品添加至乡镇专区后商品性质将改变,不再归属普通商品规则</div>
      </div>
      <el-button @click="$message.info('复制成功')" v-clipboard:copy="'/pages/plugin/chain/goods/upgrade'">复制专题页链接</el-button>
    </div>
    <el-card style="min-height: 45rem">
      <el-table :data="list" border>
        <el-table-column prop="id" label="编号" width="60"></el-table-column>
        <el-table-column prop="pic" label="商品图" width="80">
          <template #default="s">
            <img class="flex-def" :src="s.row.pic | tomedia" style="width: 3rem;height: 3rem;border-radius: .5rem"
                 alt="">
          </template>
        </el-table-column>
        <el-table-column prop="title" label="商品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fee" label="售价"></el-table-column>
        <el-table-column prop="cate" label="分类" show-overflow-tooltip>
          <template #default="s">
            {{ cateShow(s.row) }}
          </template>
        </el-table-column>
        <el-table-column :formatter="item=>item.status ? '已上架' : '未上架'" label="状态"></el-table-column>
        <el-table-column width="200">
          <template #header>
            <div class="flex-def flex-cCenter flex-zCenter">
              <span style="margin-right: 1rem">操作</span>
              <y_choose_goods @choose-ids="chooseIds" :with_type="true" :type="0"></y_choose_goods>
            </div>
          </template>
          <template #default="s">
            <el-button @click="$router.push({
            name:'goodsEdit',query:{id:s.row.id}
            })" size="mini" type="primary">编辑商品
            </el-button>
            <el-button @click="removeTheme(s.row)">撤出专区</el-button>
          </template>
        </el-table-column>
        <template #append>
          <div class="flex-def flex-zBetween" style="padding: .5rem">
            <el-pagination background layout="total,prev, pager,next"
                           :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
            </el-pagination>
          </div>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import y_choose_goods from "@/components/y_choose_goods";
import {cateShow} from "@/view/goods/tools";

export default {
  name: "upAgent",
  components: {
    y_choose_goods,
  },
  data() {
    return {
      list: [],
      total: 0,
      page: 1,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.load();
    },
    cateShow(e) {
      return cateShow(e)
    },
    removeTheme(item){
      this.$api.addons.areaAgent.goodsThemeRemove({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    chooseIds(ids) {
      this.$api.addons.areaAgent.goodsThemeSet({ids,type:4}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.$api.shop.goods.search({
        page: this.page,
        with_type: true,
        type: 4,//4乡镇产品
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    del() {
    }
  }
}
</script>

<style scoped>

</style>